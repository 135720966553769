
import { Options, Vue } from 'vue-class-component';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import UserChart from '@/components/charts/UserChart.vue';
import Pagination from '@/components/layout/Pagination.vue';
import NoData from '@/components/layout/NoData.vue';
import { Edit, Delete, Attention, CheckOne } from '@icon-park/vue-next';

@Options({
    components: {
        Breadcrumb, UserChart, Pagination, NoData,
        Edit, Delete, Attention, CheckOne
    },
    data(){
        return{
            list: {},
            notification: {
                show: false,
                content: '',
                state: 'error'
            }
        }
    },
    mounted(){
        this.getUsersList();
    },
    methods: {
        getUsersList(){
            this.$api.getUsers().then((res: any) => {
                this.list = res.data;
            })
        },
        handlerDelete(id: number, index: number){
            this.$api.destroyUser(Number(id)).then((res: any) => {
                console.log(res)
                if(res.msg === 'success') {
                    this.list.data.splice(index, 1);
                    this.notification = {
                        show: true,
                        content: '用户删除成功',
                        state: 'success'
                    }
                } else {
                    this.notification = {
                        show: true,
                        content: res.msg,
                        state: 'error'
                    }
                }
            })
        }
    }
})

export default class Users extends Vue {}
