
import { Options, Vue } from 'vue-class-component';
import { Line } from '@antv/g2plot';

@Options({
    data(){
        return{
            chart: null
        }
    },
    mounted(){
        this.$api.getUserChart(10).then((res: any) => {
            const data = res.data;
            this.chart = new Line(
                this.$refs['user-line-chart'],
                {   
                    meta: {
                        date: {
                            alias: '日期',
                        },
                        value: {
                            alias: '用户总数',
                        },
                    },
                    data,
                    xField: "date",
                    yField: "value",
                    smooth: true,
                }
            );
            this.chart.render();
        })
    }
})

export default class UserChart extends Vue {}
